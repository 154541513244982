html {
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #1e1327;
  background-image: url(../public/image/body-bg.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  color: #888888;
  background-size: 100% 100%;
}

.logo {
  width: auto;
  height: 50px;
  /*padding: 10px 10px;*/
  float: left;
  @media screen and (max-width: 768px) {
    height: 35px;
  }
}
a {
  font-family: 'Poppins', sans-serif;
  color: white;
}
.topDropdown {
  /*background: #16104e !important;*/
  float: right;
}
.topDropdown .Dropdown-control {
  /*background: #16104e !important;*/
  color: honeydew;
  border: none;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.loading-spinner {
  animation: fade 3s linear infinite;
  width: 200px;
  height: 200px;
  background: url(../public/image/logo.png);
  background-size: 100px auto;
  background-repeat: no-repeat;
  background-position: center;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #1e1327;
  background-image: url(../public/image/body-bg.jpg);
  background-position: top center;
  background-attachment: scroll;
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 900px;
  overflow-y: hidden;
  /*overflow-x: hidden;*/
}

.site-footer {
  background-color: #170f1f;
  background-image: url(../public/image/footer-bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  height: fit-content;
}
.underline-footer {
  width: 100%;
  height: 1px;
  background: #bfbfbf;
  display: inline-block;
}

.footer-title {
  color: #ff8000;
  font-size: 25px !important;
  font-variant: inherit;
}
.footer-title:hover {
  /*color: white;*/
  text-decoration: none !important;
}
.a {
  font-size: 14px;
}

.footer-item {
  color: #bfbfbf;
}

.footer-item:hover {
  color: #ff8000 !important;
}
.footer-item a {
  color: #bfbfbf !important;
  cursor: pointer;
  padding-top: 12px;
  text-decoration: none;
}
.footer-item a:hover {
  color: #ff8000;
  text-decoration: none;
}
.content-wrapper {
  margin-top: 50px;
  padding-left: 30px;
}
.copy-right {
  padding: 5px 20px;
  text-align: center;
  color: #bfbfbf;
}
.search-input {
  background-image: url('./components/share-image/icons8-search-more-32.png');
  display: inline-block;
  width: 230px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: 100% 0;
  vertical-align: middle;
  border: 0.2px solid #888888;
}

.cursor-pointer {
  cursor: pointer;
}

