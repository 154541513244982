.authen-form {
  width: 100%;
  margin: 0 auto;
  height: 500px;
  background-color: white;
}
.form-authen {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-top: 5%;
  min-height: 550px;
  /* border-radius: 2em; */
  /* box-shadow: rgba(0 0 0/3%) 0 0 1em, rgba(0 0 0/5%) 0 1em 2em, rgba(0 0 0/3%) 0 2em 4em; */
  overflow: hidden;
  display: flex;
  background-image: url('./service-image/wallpaper.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.register-guide {
}
.advertisement {
  /*box-shadow: rgba(0 0 0/3%) 0 0 1em, rgba(0 0 0/5%) 0 1em 2em, rgba(0 0 0/3%) 0 2em 4em;*/
  width: fit-content;
  border-radius: 5px;
  padding: 10px 10px;
  text-align: left !important;
  color: #888888;
}
.form {
  padding: 5px 5px;
}
.error-text {
  padding-left: 20px;
  font-size: 10px;
  color: red;
}
.error-text-small {
  padding-left: 20px;
  font-size: 10px;
  color: red;
  line-height: 10px;
}
#sign_up{
  color: #888888;
}
#sign_in{
  color: #888888;
}
#forget_password{
  color: #888888;

}
