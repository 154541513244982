.container-about-us p {
  text-align: justify;
}

.container-about-us h2 {
  text-transform: uppercase;
  color: #ff8000dd;
}

.about-us-article {
  margin-bottom: 2em;
}

.about-us-article img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .about-us-article img {
    width: 12em;
    height: auto;
    margin-bottom: 1em;
  }
}
