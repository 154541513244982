.sub-nav {
  background-image: url(gradient2.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: repeat-x, no-repeat;
  width: 100%;
}

.game-cover {
  grid-row: span 2;
  align-self: flex-end;
  float: right;
  perspective: 25em;
  position: relative;
  z-index: 1;
  width: 60%;
}

.cover {
  position: relative;
  width: 95%;
  border-radius: 14px;
  overflow: hidden;
  background: #fff linear-gradient(0deg, rgba(0 0 0/5%), rgba(0 0 0/0));
  transform-origin: center left;
  transform: rotateY(10deg);
  aspect-ratio: 3/4;
  margin-bottom: -15em;
}

.game-cover .cover img {
  width: 100%;
  height: 100%;
  vertical-align: top;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.content-detail {
  color: white;
  font-size: 12px;
  float: right;
}

.content-des {
  opacity: 0.75;
  align-items: center;
  justify-items: center;
  height: 100%;
}

.item-content-card {
  /*background-color: #1e1327;*/
  height: 100%;
  padding: 20px 0;
}

.search-detail-bar {
  padding: 20px 20px;
  background-color: #2e2039;
  color: #ffff;
  border-radius: 10px;
}

.seach-content {
  margin-top: 20px;
  border: 0.5px solid #c4c0c0;
  padding: 20px 15px;
  border-radius: 5px;
  font-size: 10px;
}

select {
  height: 21px;
  width: 230px;
}

.child-search-content {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.active {
  background-color: #ebf3ff;
  color: #ff8000;
}

.search-bar-icon-filter:hover {
  /*background-color: #e8e8e8;*/
  color: #ff8000;
}

.search-bar-icon-filter {
  padding: 9px 12px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 11px;
}
.change-content {
  width: 60%;
  position: absolute;
  bottom: 0;
  background-color: rebeccapurple;
}

.ck .ck-sticky-panel {
  display: none !important;
}
.view-type-button:hover {
  color: #ff8000;
  border: 0.5px solid #ff8000;
}
.view-type-button {
  height: 50px;
  padding: 5px 5px;
  border-radius: 5px;
  border: 0.5px solid #888888;
  cursor: pointer;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: #2e2039 !important;
  border: none;
  color: white;
}
.title-center {
  text-align: center;
  color: white;
}

.pack-content:hover {
  color: #ff8000;
}
.pack-content {
  margin: 20px auto;
  border: 0.5px solid #c4c0c0;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  background-color: #2e2039;
  color: #ffffff;
  width: 95%;
}
.pack-content-detail {
  margin: 20px auto;
  border: 0.5px solid #c4c0c0;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  background-color: #2e2039;
  color: #ffffff;
  width: 95%;
}

.icon-button {
  cursor: pointer;
}
.unit-package {
  color: #888888;
}
.react-star-rate {
  font-size: 15px !important;
}
.pack-content-mobile:hover {
  color: #ff8000;
}
.pack-content-mobile {
  margin: 4px auto;
  border: 0.5px solid #c4c0c0;
  padding: 10px 10px !important;
  border-radius: 5px;
  font-size: 12px;
  background-color: #2e2039;
  color: #ffffff;
  width: 95%;
}
.crop {
  width: 70px;
  height: 70px;
  overflow: hidden;
}

.crop img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}
.package-name {
  cursor: pointer;
}
.currency {
  color: #ff8000;
  font-weight: bold;
}

.view-more-button:hover {
  color: #ff8000;
  cursor: pointer;
}

.grid-container {
  display: grid;
  column-gap: 1em;
  grid-template-columns: 200px auto;
  row-gap: 0;
  grid-template-areas:
    'a b'
    'a c'
    'a c'
    'a c'
    'a c'
    'a c'
    'a c';
}

@media screen and (max-width: 768px) {
  .grid-container {
    display: grid;
    row-gap: 2em;
    grid-template-columns: 120px auto;
    grid-template-areas:
      'a b'
      'c c';
  }
}

