.service-container1{
    width:98%;
    margin-left:auto ;
    margin-right:auto;
    display: flex;
    margin-top: 40px;
    cursor: pointer;
}
.center-title{
    font-size: 40px;
    line-height: 38px;
    letter-spacing: 0;
    font-weight: 500;
    color: whitesmoke;
    /*background: #f9aa01;*/
    text-transform: initial;
    font-style: italic;
    text-align: center;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}
.center-title:hover{
    /*color: #f9aa01;*/
    /*border: 1px solid #ff8000;*/
}


.img-hover-zoom {
    height: 550px; /* [1.1] Set it as per your need */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
    width: 446px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
    position: relative;
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
    transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
    transform: scale(1.5);
}
/* Quick-zoom Container */
.img-hover-zoom--quick-zoom img {
    transform-origin: 0 0;
    transition: transform .25s, visibility .25s ease-in;
}

/* The Transformation */
.img-hover-zoom--quick-zoom:hover img {
    transform: scale(2);
}
.image-footer{
    width: 100%;
    height: 100px;
    background-color: #3e3446;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    text-align: left;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.image-footer:hover{
    background-color: black;
}
.shop-now{
color: #ff8000;
    font-variant: none;
}
.game-name{
    color: white;
    font-size: 1.25rem;
}

.img-block {
    position: relative;
    overflow: hidden;
    width: 80%;
    transition: 0.5s;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    border-radius: 10px;
}

.img-block:hover {
    box-shadow: 0 30px 35px rgba(0, 0, 0, 0.7);
}
.img-block span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -120%;
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.4s;
    transform: skewX(10deg);
}

.img-block img {
    width: 100%;
}

.img-block:hover span {
    left: 120%;
}

/* PRESENTATION */

/*.img-block {*/
/*    background-color: #667;*/
/*    height: 425px;*/
/*}*/
.image-content{
    width: 33%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

