.content-blog {
	background-color: #2e2039;
	color: white;
	margin-top: 10px;
}

.blog-content-detail {
	color: white;
	font-size: 14px;
	justify-content: center;
}

.blog-content {
	clear: both;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	line-height: 1.5em;
	height: 3em;
	overflow: hidden;
	width: 100%;
	color: white;
}

.ellipsis-text {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: pre-wrap;
}

.blog-title-detail {
	color: white;
	font-size: 22px;
}

.blog-title {
	clear: both;
	font-size: 18px;
	overflow: hidden;
	height: 3em;
	color: white;
}

.blog-info {
	font-size: 12px;
	color: #888888;
}

.hover-blog:hover {
	color: #ff8000;
}

