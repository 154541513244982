.game-container{
    width: 100%;
    height: 500px;
}
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 3/4;
}
